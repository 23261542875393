<template>
	<div v-myTitle :data-title="shop.name+'-'+'服务中心'+'-'+'企胖胖'">
		<!-- <div v-title :data-title="shop.name"></div> -->
		<Search />
		<div class="head">
			<div class="nav">
				<div class="bor" @click="goServe">
					<i style="font-size: 20px; padding-top: 2px" class="el-icon-arrow-left"></i>
					返回服务中心
				</div>
				<a href="#good">
					<div class="bor">商品服务</div>
				</a>
				<a href="#boos">
					<div class="bor">公司介绍</div>
				</a>
				<!-- <div >服务评价</div> -->
			</div>
		</div>
		<div class="shop-box">
			<div class="chat-img" @click="watchFun">
				<img src="../../assets/images/serve/wechat.png" alt="" />
			</div>
			<div class="shop-info">
				<div class="shop-img">
					<img :src="$util.host + shop.headPic" alt="" />
				</div>
				<div style="flex: 1">
					<div class="shop-title">
						<div class="shop-name">{{ shop.name }}</div>
						<img style="width: 33px; height: 22px" src="../../assets/images/serve/qilogo.png" alt=""
							v-if="shop.type == '2'" />
						<div class="shop-tag">
							<div class="bor">
								近一年成交率: <span>{{ shop.overRate }}%</span>
							</div>
							<div class="bor">
								好评率: <span>{{ shop.evaluateRate }}% </span>
							</div>
							<div>
								近一年发布量: <span>{{ shop.allGoodsSize }}单</span>
							</div>
						</div>
						<div>开店时间 {{ shop.createTime }}</div>
						<img class="dw" src="../../assets/images/serve/dw.png" alt="" />
						<div>{{province}}{{city}}</div>
					</div>
					<div class="shop-business">
						业务范围：<span>{{ shop.msg }}</span>
					</div>
					<div class="shop-business">
						<div>
							经营类别：<span v-for="(item,index) in category"> <span v-if="index!==0">|</span> {{item.name}}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 轮播图 -->
		<el-carousel height="600px" arrow="never" trigger="click">
			<el-carousel-item v-for="(item, index) in shop.images" :key="index">
				<div class="swiper">
					<img :src="$util.host + item" alt="" />
				</div>
			</el-carousel-item>
		</el-carousel>
		<div class="mian" id="good">
			<div class="main-title" id="main_title">
				<div class="title">
					商品服务
				</div>
				<div class="search">
					<div class="enter">
						<img src="../../assets/images/搜索.png" @click="search" alt="">
						<input type="text" placeholder="请输入服务名称进行搜索" v-model="shopSelect.goodsName" class="input"
							@keyup.enter="search" />
						<!-- <button class="enter-btn" @click="search">搜索</button> -->
					</div>
				</div>
			</div>
			<!-- 分类 -->
			<div class="classification">
				<div class="slece">
					<el-dropdown trigger="click" placement="bottom" @command="shopType">
						<span class="el-dropdown-link">
							类别<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item :command="item" v-for="item in clssList" :key="item.id">{{
                item.name
              }}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="slece">
					<div class="areas">
						<div @click="shopShow = !shopShow">
							<span>地区</span> <i class="el-icon-arrow-down"></i>
						</div>
						<div class="down" v-show="shopShow">
							<div :class="shopProIndex == index ? 'active' : ''" class="down-item"
								v-for="(item, index) in shopProList" :key="item.id"
								@click="changeShopCityIndex(item, index)">
								<div>
									<span>{{ item.province }}</span> <i class="el-icon-arrow-right"></i>
								</div>
							</div>
						</div>
						<div class="downt" v-show="shopShow">
							<div v-if="shopProList[shopProIndex]">
								<div :class="shopCityIndex == index ? 'active' : ''" class="down-item"
									v-for="(item, index) in shopProList[shopProIndex].cities" :key="item.id"
									@click="getShopCityId(item, index)">
									<div>
										<span>{{ item.city }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="slece">
					<el-dropdown trigger="click" placement="bottom" @command="getShopTime">
						<span class="el-dropdown-link">
							上架时间<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item :command="item" v-for="item in shopTime" :key="item.id">{{
                item.title
              }}</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<!-- 分类内容 -->
			<div class="classification-content">
				<div class="nice-shop">
					<div class="good" v-for="item in goods" :key="item.id" @click="goDetail(item.id, shop.name)">
						<img :src="$util.host + item.cover" alt="" />
						<div>{{ item.name }}</div>
						<span>预算￥{{ item.budget }}</span>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :page-size="shopPage.pageSize"
					@current-change="changePage" :total="shopPage.totalRows" :current-page="shopSelect.pageNo">
				</el-pagination>
				<div class="total">共{{ shopPage.totalRows }}件/{{ shopPage.totalPage }}页</div>
				<div class="jump">
					跳转到
					<input type="number" v-model="shopSelect.pageNo" />
					页
				</div>
				<el-button style="
            background: #2ea7e0;
            color: #fff;
            width: 108px;
            height: 50px;
            font-size: 20px;
          " @click="goShopPage">前往</el-button>
			</div>
		</div>
		<div class="introduce" id="boos">公司介绍</div>
		<div class="introduce-title" v-show="item.content!==''" v-for="item in html" :key="item.id">
			{{ item.title }}
			<div v-html="item.content" class="content"></div>
		</div>

		<!-- 弹出微信 -->
		<el-dialog :visible.sync="wachtShow" width="300px">
			<div class="wechat">微信扫一扫与我们沟通更方便</div>
			<div class="wechat-img">
				<img :src="$util.host + shop.wxCode" alt="" />
			</div>
		</el-dialog>
		<Footer />
	</div>
</template>

<script>
	import Search from "../../components/search.vue";
	import Footer from "../../components/footer.vue";
	import eventBus from '@/components/eventBus.js'
	// import mix from "../../mixins/index"
	export default {
		// mixins: [mix],
		components: {
			Search,
			Footer
		},
		data() {
			return {
				star: 5,
				colors: ["#E40012", "#E40012", "#E40012"],
				wachtShow: false, //弹出微信
				id: this.$route.query.id, //店铺ID
				shop: {}, //店铺信息
				category: {}, //经营类别
				goods: [], //商品列表
				clssList: [], //分类数组
				shopShow: false, //省显示
				shopProList: [], //省
				shopProIndex: 0, //省下标
				shopCityIndex: 0, //市下标
				shopTime: [
					//时间筛选
					{
						id: 0,
						title: "3天内",
						day: 3
					},
					{
						id: 1,
						title: "一周内",
						day: 7
					},
					{
						id: 2,
						title: "一个月内",
						day: 30
					},
					{
						id: 3,
						title: "3个月内",
						day: 90
					},
					{
						id: 4,
						title: "半年内",
						day: 180
					},
					{
						id: 5,
						title: "1年内",
						day: 365
					},
					{
						id: 6,
						title: "3年内",
						day: 1095
					},
				],
				publisherType: [{
						id: 2,
						title: "企业用户"
					},
					{
						id: 1,
						title: "个人用户"
					},
				], // 发布者类型
				shopSelect: {
					//店铺商品筛选参数
					state: "1",
					addr: "全部",
					typeId: 0,
					dayType: 0,
					shopId: this.$route.query.id,
					pageNo: 1,
					pageSize: 25,
					goodsName: "", //搜索内容
				},
				shopNum: "",
				shopPage: {}, //需求总页数
				html: [],
				provinceList: [], //省列表
				province: "",
				cityList: [], //市列表
				city: "",
				searchKey: "", //搜索内容
			};
		},
		mounted() {
			window.scrollTo(0, 0);
			this.init()
			this.allData();
			this.getProvince();
			this.getClass();
			if (this.$route.query.shopNum != undefined && this.$route.query.shopNum != '') {
				this.shopSelect.pageNo = JSON.parse(this.$route.query.shopNum)
			}
			// this.$mix()
		},
		methods: {
			//搜索需求
			search() {
				// if (this.shopSelect.goodsName == "") {
				//     this.$message.error("请输入搜索内容");
				// } else {
				this.$util.post("/demandPurchase/goodsList", this.shopSelect).then((res) => {
					console.log(res);
					this.shopPage = res.pager;
					this.goods = res.data;
				});

				// }

			},
			watchFun() {
				if (
					sessionStorage.getItem("token") == undefined ||
					sessionStorage.getItem("token") == ""
				) {
					return this.$message.error("请先进行登录");
				} else {
					this.wachtShow = true;
				}
			},
			allData() {
				this.$util
					.post("/demandPurchase/shopDet", {
						shopId: this.id,
					})
					.then((res) => {
						console.log(res);
						this.goods = res.data.goods.data;
						this.shop = res.data.shop;
						this.category = res.data.shop.shopClassify
						this.html = res.data.html;
						var aa = this.shop.images.split(",");
						this.shop.images = aa;
						this.getShopList();
						//获取市列表
						this.$util.post('/address/city', {
							fid: this.shop.province
						}).then(res => {
							console.log(res.data);
							this.cityList = res.data
							this.cityList.forEach(el => {
								if (this.shop.city == el.cityid) {
									this.city = el.city
								}
							})
						})
						this.provinceList.forEach(el => {
							if (this.shop.province == el.provinceid) {
								this.province = el.province
							}

						})
					});
			},
			getClass() {
				this.$util.post("/shop/allClassify").then((res) => {
					this.clssList = res.data;
				});
			},
			// 获取店铺列表
			getShopList() {
					this.$util.post("/demandPurchase/goodsList", this.shopSelect).then((res) => {
						console.log(res);
						this.shopPage = res.pager;
						this.goods = res.data;
					});
			},
			// 获取省
			getProvince() {
				this.$util.post("/address/all").then((res) => {
					for (let key in res.data) {
						this.shopProList.push(res.data[key]);
					}
				});
			},
			// 选择省
			changeShopCityIndex(item, index) {
				this.shopProIndex = index;
				// this.demandScreening.sendProvince = item.provinceid
			},
			// 获取市ID
			getShopCityId(item, index) {
				this.shopShow = false;
				this.shopCityIndex = index;
				this.shopSelect.addr = item.provinceid;
				this.getShopList();
			},
			// 时间筛选
			getShopTime(item) {
				this.shopSelect.dayType = item.day;
				this.getShopList();
			},
			// 类别
			shopType(item) {
				this.shopSelect.typeId = item.id;
				this.getShopList();
			},
			changePage(num) {
				document.getElementById('main_title').scrollIntoView({
					block: 'center'
				});

				this.shopSelect.pageNo = num;
				this.getShopList();
			},
			goShopPage() {
				// console.log()
				// this.shopSelect.pageNo = this.shopNum;
				this.getShopList();
			},
			goDetail(id, name) {
				this.$router.push({
					path: "/shopDetail",
					query: {
						id,
						name,
						shopNum: this.shopSelect.pageNo,
						bigId: this.$route.query.id
					}
				});
			},
			goServe() {
				this.$router.push({
					path: "/serve"
				});
			},
			init() {
				this.$util.post('/address/provinces').then(res => {
					this.provinceList = res.data
				})

			}
		},
	};
</script>
<style>
	.content img {
		width: 100%;
	}
</style>
<style lang="scss" scoped>
	a {
		color: #fff;
	}

	.head {
		padding: 12px 0;
		background: #515151;

		.nav {
			width: 1200px;
			display: flex;
			margin: 0 auto;
			color: #fff;

			div {
				display: flex;
				cursor: pointer;
				align-items: center;
				padding-right: 53px;
				font-size: 14px;
			}

			.bor {
				border-right: 1px solid #707070;
				margin-right: 50px;
			}
		}
	}

	.shop-box {
		position: relative;
		margin: 26px 0 40px;
	}

	.chat-img {
		position: absolute;
		top: 0px;
		right: 70px;
		width: 149px;
		height: 50px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.shop-info {
		width: 1200px;
		margin: 0 auto;
		display: flex;

		.shop-img {
			width: 198px;
			height: 198px;
			margin-right: 20px;

			// background: #515151;
			img {
				width: 100%;
				height: 100%;
			}
		}

		.shop-title {
			display: flex;
			align-items: center;
			font-size: 14px;
			color: #666;
			margin-bottom: 20px;

			.shop-name {
				font-size: 20px;
				color: #222222;
				margin-right: 10px;
				width: 120px;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				display: -webkit-box;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.shop-tag {
				display: flex;
				margin: 0 80px 0 60px;

				div {
					font-size: 14px;
					color: #666;
					padding-right: 20px;

					span {
						color: #e40012;
					}
				}

				.bor {
					border-right: 1px solid #707070;
					margin-right: 20px;
				}
			}

			.dw {
				width: 19px;
				height: 24px;
				display: block;
				margin: 0 9px 0 32px;
			}
		}

		.shop-business {
			padding: 20px 30px 20px 20px;
			background: #f6f6f6;
			color: #666666;
			margin: 0 0 10px;
		}
	}

	.swiper {
		// background: lime;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
		}
	}

	/deep/.el-carousel__button {
		width: 22px;
		height: 22px;
		background: #fff;
		border-radius: 100%;
		opacity: 1;
	}

	/deep/.el-carousel__indicator.is-active button {
		background: #e40012;
	}

	.mian {
		width: 1200px;
		margin: 80px auto 30px;
		border: 1px solid #e3e3e3;
	}

	.main-title {
		display: flex;
		padding: 17px 20px;
		font-size: 20px;
		color: #222;
		background: #f2f2f2;
		align-items: center;
		justify-content: space-between;

		.title {}

		.search {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 302px;

			// background-color: #fff;
			.enter {
				width: 280px;
				height: 50px;
				display: flex;
				align-items: center;
				border: 1px solid #e40012;
				border-radius: 6px;
				background-color: #fff;
				padding: 0 10px;

				.input {
					flex: 1;
					// padding: 0 20px;
					width: 200px;
					height: 50px;
					border: none;
					outline: none;
				}

				.enter-btn {
					width: 50px;
					height: 100%;
					background: #e40012;
					border: none;
					cursor: pointer;
					color: #fff;
					font-size: 16px;
				}
			}
		}
	}

	// 分类
	.classification {
		background: #fff;
		padding: 20px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #e3e3e3;
		border-top: 1px solid #e3e3e3;
	}

	.slece {
		cursor: pointer;
		margin-right: 80px;
		font-size: 16px;
		font-weight: bold;
	}

	.classification-content {
		// width: 1200px;
		// margin: 0 auto;
		background: #fff;
	}

	.pagination {
		// width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 60px;
		border: 1px solid #e3e3e3;
	}

	/deep/.el-pager li {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px !important;
		border: 1px solid #2ea7e0;
		margin: 0 3px;
		color: #2ea7e0 !important;
	}

	/deep/.el-pagination.is-background .el-pager li {
		background: transparent;
	}

	/deep/.el-pagination .btn-next,
	/deep/.el-pagination .btn-prev {
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px !important;
		// border: 1px solid #2ea7e0;
		background: transparent;
		margin: 0 3px;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		color: #fff !important;
	}

	/deep/.el-pagination .btn-next .el-icon,
	/deep/.el-pagination .btn-prev .el-icon {
		font-size: 24px;
		color: #2ea7e0;
	}

	/deep/.el-pager li.active+li {
		border: 1px solid #2ea7e0;
	}

	.total {
		color: #2ea7e0;
		font-weight: bold;
		font-size: 16px;
	}

	.jump {
		display: flex;
		align-items: center;
		font-size: 16px;
		margin: 0 20px 0 40px;
		color: #2ea7e0;

		input {
			width: 40px;
			height: 40px;
			border: 1px solid #0f6c97;
			font-size: 14px;
			color: #2ea7e0;
			outline: none;
			margin: 0 10px;
			border-radius: 4px;
		}
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	.nice-shop {
		display: flex;
		flex-wrap: wrap;
	}

	.good {
		width: 198px;
		padding: 20px;
		border: 1px solid #e3e3e3;

		img {
			width: 100%;
			height: 178px;
		}

		div {
			font-size: 14px;
			color: #222222;
			margin: 20px 0 10px;
		}

		span {
			color: #e40012;
			font-size: 14px;
		}
	}

	.introduce {
		width: 1200px;
		margin: 0 auto;
		font-size: 24px;
		color: #222222;
		font-weight: bold;
	}

	.introduce-title {
		padding: 30px 0 60px;
		background: #f8f8f8;
		font-size: 18px;
		font-weight: bold;
		color: #222222;
		text-align: center;
		margin: 30px 0 20px;

		div {
			width: 1200px;
			margin: 30px auto 20px;
			text-align: left;
			font-size: 14px;
			font-weight: normal;
		}
	}

	.introduce-title-t {
		padding: 30px 0 60px;
		background: #f8f8f8;
		font-size: 18px;
		font-weight: bold;
		color: #222222;
		text-align: center;
		margin-bottom: 20px;

		.sange {
			display: flex;
			justify-content: space-between;
			width: 1200px;
			margin: 50px auto 0;

			div {
				width: 277px;
				height: 370px;
				border: 1px solid #707070;
				background: #fff;
			}
		}

		.title-f {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 800px;
			margin: 50px auto 0;

			div {
				width: 183px;
				height: 183px;
				border: 1px solid #707070;
				background: #fff;
				border-radius: 100%;
				margin-right: 50px;
				margin-bottom: 50px;
			}
		}
	}

	.introduce-title-f {
		padding: 30px 0 34px;
		background: #f8f8f8;
		margin-bottom: 30px;

		.f-title {
			color: #222222;
			font-size: 18px;
			text-align: center;
			display: block;
			margin-bottom: 80px;
			font-weight: bold;
		}

		div {
			width: 1200px;
			margin: 0 auto 20px;
			height: 400px;
			border: 1px solid #707070;
			background: #fff;
		}
	}

	.comment-box {
		width: 1200px;
		margin: 0 auto 20px;
	}

	.comment {
		display: flex;
		padding: 30px 20px;
		border-bottom: 1px solid #f1f1f1;

		img {
			width: 82px;
			height: 82px;
		}

		.comment-info {
			flex: 1;
			margin-left: 20px;
			font-size: 14px;
			color: #999999;

			.user-info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: #222;

				.name {
					display: flex;
					align-items: center;

					div {
						margin-right: 20px;
					}
				}
			}
		}
	}

	.comment-content {
		margin: 13px 0 20px;
		font-size: 14px;
		color: #666;
	}

	.comment-serve {
		margin-bottom: 10px;

		span {
			color: #e40012;
		}
	}

	.wechat {
		font-size: 16px;
		text-align: center;
		color: #222;
	}

	.wechat-img {
		width: 191px;
		height: 191px;
		margin: 30px auto 0;

		// background: red;
		img {
			width: 100%;
			height: 100%;
		}
	}

	.areas {
		display: flex;
		align-items: center;
		position: relative;
		font-size: 14px;
		color: #606266;

		i {
			margin-left: 4px;
		}

		.down {
			position: absolute;
			top: 45px;
			left: -20px;
			z-index: 1000;
			// right: 0;
			// padding: 10px;
			// box-shadow: 1px 1px 10px #f1f1f1;
			max-height: 500px;
			overflow-y: auto;

			.down-item {
				background: #fff;
				width: 150px;
				padding: 10px;
				font-size: 14px;
				// position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.active {
				background: #2ea7e0;
				color: #fff;
				width: 150px;
				padding: 10px;
				font-size: 14px;
				// position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		.downt {
			position: absolute;
			z-index: 1000;
			top: 45px;
			left: 150px;
			// right: 0;
			// padding: 10px;
			// box-shadow: 1px 1px 10px #f1f1f1;
			max-height: 500px;
			overflow-y: auto;

			.down-item {
				background: #fff;
				width: 150px;
				padding: 10px;
				font-size: 14px;
				// position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.active {
				background: #2ea7e0;
				color: #fff;
				width: 150px;
				padding: 10px;
				font-size: 14px;
				// position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.down-item:hover {
				background: #2ea7e0;
				color: #fff;
			}
		}
	}

	.down::-webkit-scrollbar {
		display: none;
	}

	.downt::-webkit-scrollbar {
		display: none;
	}
</style>
